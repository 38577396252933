/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import phoneInfo from "../../assets/helpInfo.svg"; // Ajusta la ruta según corresponda
import Step1 from "./CompanyPhoneInput";
import Step2 from "./CompanyChangeNameConfirmation";
import Step3 from "./CompanyChangeNameInput";
import { post, put } from "../../api/api.js";
import RadioButtonCategories from "./RadioButtonCategories";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { REGIONS } from "../../helpers/constants";
import { isValidEmail } from "../../helpers/functions.js";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const serviceObject = {
  icon: AttachMoneyIcon, // Icono general para todos los inputs
  placeHolder: "Price", // Placeholder general para todos los inputs
  categories: [
    {
      key: "towing",
      title: "Towing",
      subCategories: {
        standard: { title: "Standard hook up fee" },
        flatbed: { title: "Flatbed hook up fee" },
        mileageRate: { title: "Mileage Rate" },
      },
    },
    {
      key: "tireChange",
      title: "Tire Change",
      subCategories: {
        spareTire: { title: "Spare Tire" },
        tireReplacement: { title: "New Tire" },
      },
    },
    {
      key: "refuel",
      title: "Refuel",
      subCategories: {
        diesel: { title: "Diesel" },
        midgradeGasoline: { title: "Midgrade Gasoline" },
        premiumGasoline: { title: "Premium Gasoline" },
        regularGasoline: { title: "Regular Gasoline" },
      },
    },
    {
      key: "battery",
      title: "Battery",
      subCategories: {
        jump: { title: "Jump" },
        replacement: { title: "Replacement" },
      },
    },
    {
      key: "unlock",
      title: "Unlock",
      subCategories: {
        lockedOut: { title: "Locked Out" },
        lostKeys: { title: "Lost Keys" },
      },
    },
  ],
};

const openingHoursObject = {
  icon: AccessTimeIcon, // Icono general para todos los inputs
  placeHolder: "Hour", // Placeholder general para todos los inputs
  categories: [
    {
      key: "sunday",
      title: "Sunday",
      subCategories: {
        startTime: { title: "Start time" },
        endTime: { title: "End time" },
      },
    },
    {
      key: "monday",
      title: "Monday",
      subCategories: {
        startTime: { title: "Start time" },
        endTime: { title: "End time" },
      },
    },
    {
      key: "tuesday",
      title: "Tuesday",
      subCategories: {
        startTime: { title: "Start time" },
        endTime: { title: "End time" },
      },
    },
    {
      key: "wednesday",
      title: "Wednesday",
      subCategories: {
        startTime: { title: "Start time" },
        endTime: { title: "End time" },
      },
    },
    {
      key: "thursday",
      title: "Thursday",
      subCategories: {
        startTime: { title: "Start time" },
        endTime: { title: "End time" },
      },
    },
    {
      key: "friday",
      title: "Friday",
      subCategories: {
        startTime: { title: "Start time" },
        endTime: { title: "End time" },
      },
    },
    {
      key: "saturday",
      title: "Saturday",
      subCategories: {
        startTime: { title: "Start time" },
        endTime: { title: "End time" },
      },
    },
    {
      key: "open24/7",
      title: "Open 24/7",
      subCategories: {},
    },
  ],
};

const buttons = [
  {
    text: "Change company data",
    onClick: () => {},
  },
  {
    text: "Don't change anything",
    onClick: () => {},
  },
];

const Form = () => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const [step, setStep] = useState(0);

  const [smsEnabled, setSmsEnabled] = useState(true);

  const [regionIndex, setRegionIndex] = useState(0);

  const [email, setEmail] = useState("");

  const [companyName, setCompanyName] = useState("");

  const [componentLoading, setComponentLoading] = useState(false);

  const [selectedButton, setSelectedButton] = useState(false);

  const [changeCompanyName, setChangeCompanyName] = useState(false);

  const [company, setCompany] = useState({});

  const [token, setToken] = useState("");

  const [openingHours, setOpeningHours] = useState(openingHoursObject);

  const [service, setService] = useState(serviceObject);

  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);

  const handleInputChange = (value) => {
    setCompanyName(value);
  };

  const handlePhoneNumberChange = (event) => {
    // const region = REGIONS.find((region) => region.code === regionCode); // Obtener el código de la región seleccionada
    // Eliminar todos los caracteres no numéricos del valor ingresado
    let formattedPhoneNumber = event.target.value.replace(/\D/g, "");
    if (event.target.value.includes(REGIONS[regionIndex].code)) {
      formattedPhoneNumber = formattedPhoneNumber.slice(
        REGIONS[regionIndex].code.length - 1,
        formattedPhoneNumber.length,
      );
    }
    // Aplicar formato (XXX) XXX-XXXX si hay suficientes dígitos
    let formattedValue = "";
    if (formattedPhoneNumber.length >= 1) {
      formattedValue += `(${formattedPhoneNumber.slice(0, 3)}`;
    }
    if (formattedPhoneNumber.length > 3) {
      formattedValue += `) ${formattedPhoneNumber.slice(3, 6)}`;
    }
    if (formattedPhoneNumber.length > 6) {
      formattedValue += `-${formattedPhoneNumber.slice(6, 10)}`;
    }

    if (formattedValue.length > 0) {
      formattedValue = `${REGIONS[regionIndex].code} ${formattedValue}`;
    }

    setPhoneNumber(formattedValue);
  };

  const formStep = [
    {
      title: ["Enter your", "phone", "number"],
      description:
        "Use the phone number from which you received the SMS with the link to this page.",
      component: (
        <Step1
          handlePhoneNumberChange={handlePhoneNumberChange}
          phoneNumber={phoneNumber}
          smsConsent={smsEnabled}
          handleSmsConsentChange={setSmsEnabled}
          regionIndex={regionIndex}
          setRegionIndex={setRegionIndex}
          resetPhoneNumber={() => setPhoneNumber("")}
        />
      ),
      buttons: ["Next"],
    },
    {
      title: ["", "Welcome,", ` ${companyName}!`],
      description: `Want to change your phone number, company name, or email? Click on "Change company data" to change the data.`,
      component: (
        <Step2
          buttons={buttons}
          setChangeCompanyName={setChangeCompanyName}
          setSelected={setSelectedButton}
        />
      ),
      buttons: ["Next", "Back"],
    },
    {
      title: ["", "Welcome,", ` ${companyName}!`],
      description: `Please enter your email, company name, and phone number.`,
      component: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5vh",

            marginBottom: { md: "4vh", lg: "4vh", xl: 0 },
          }}
        >
          <Step1
            handlePhoneNumberChange={handlePhoneNumberChange}
            phoneNumber={phoneNumber}
            smsConsent={smsEnabled}
            handleSmsConsentChange={setSmsEnabled}
            regionIndex={regionIndex}
            setRegionIndex={setRegionIndex}
            resetPhoneNumber={() => setPhoneNumber("")}
          />
          <Step3
            inputValue={companyName}
            onInputChange={handleInputChange}
            icon={BusinessCenterOutlinedIcon}
            placeholder="Company name"
          />
          <Step3
            inputValue={email}
            onInputChange={setEmail}
            icon={EmailOutlinedIcon}
            placeholder="Email"
          />
        </Box>
      ),
      buttons: ["Next", "Back"],
    },
    {
      title: ["Types of", "services", "offered"],
      description: `What kind of services do you offer?`,
      component: (
        <RadioButtonCategories
          data={service}
          setCategories={setService}
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
        />
      ),
      buttons: ["Next", "Back"],
    },
    {
      title: ["", "Opening", "hours"],
      description: `What are your workshop's opening hours?`,
      component: (
        <RadioButtonCategories
          data={openingHours}
          twoInRow={true}
          dateSelection={true}
          setCategories={setOpeningHours}
          selectedServices={selectedDays}
          setSelectedServices={setSelectedDays}
        />
      ),
      buttons: ["Next", "Back"],
    },
    {
      title: ["", "Thanks", "for sharing!"],
      description: `Now more people will be able to find your services through our app.`,
      component: <></>,
      buttons: [],
    },
  ];

  const handleNextButton = async () => {
    try {
      if (step === 1) {
        if (changeCompanyName) {
          setStep((prevState) => prevState + 1);
        } else {
          setStep((prevState) => prevState + 2);
        }
        return;
      }

      if (step === 4) {
        putInformationOnObject();
        return;
      }

      if (step === 0) {
        const filteredPhoneNumber = phoneNumber.replace(/\D/g, "");
        setComponentLoading(true);
        const response = await post("/auth/company/aggregate", {
          phone: filteredPhoneNumber,
        });
        setComponentLoading(false);

        let { token: auxToken, ...auxCompany } = response.payload;

        setCompany(auxCompany);
        setToken(auxToken);
        setCompanyName(auxCompany.name);
        setEmail(auxCompany.contact.email ?? "");

        console.log("Aux company------<", auxCompany);
        const updatedService = { ...service };
        updatedService.categories.forEach((category) => {
          if (auxCompany.basePrices[category.key]) {
            Object.keys(category.subCategories).forEach((subKey) => {
              if (subKey === "mileageRate") {
                category.subCategories.mileageRate.value = {
                  basePrice: (
                    Number(auxCompany.basePrices.towing.standard.mileageRate) /
                    100
                  ).toFixed(2),
                };
                return;
              }
              if (auxCompany.basePrices[category.key][subKey]) {
                category.subCategories[subKey].value =
                  auxCompany.basePrices[category.key][subKey];
                if (category.subCategories[subKey].value.new) {
                  category.subCategories[subKey].value.new.basePrice = (
                    category.subCategories[subKey].value.new.basePrice / 100
                  ).toFixed(2);
                } else if (category.subCategories[subKey].value.basePrice) {
                  category.subCategories[subKey].value.basePrice = (
                    category.subCategories[subKey].value.basePrice / 100
                  ).toFixed(2);
                }
              }
            });
          }
        });
        for (let index in auxCompany.specialties.specialties) {
          let specialty = auxCompany.specialties.specialties[index];
          setSelectedServices((prevState) => [...prevState, specialty.name]);
        }
        const updatedOpeningHours = { ...openingHours };
        let allDays24hours = true;
        updatedOpeningHours.categories.forEach((day) => {
          if (auxCompany.detail.currentOpeningHours[day.key]) {
            const hours = auxCompany.detail.currentOpeningHours[day.key][0];
            if (hours.open !== "closed") {
              setSelectedDays((prevState) => [...prevState, day.key]);
            }
            if (hours.open === "Open 24 hours") {
              day.subCategories.startTime.value = "24 hours";
            } else {
              allDays24hours = false;
              day.subCategories.startTime.value = hours.open;
            }

            day.subCategories.endTime.value = hours.close;
          }
        });
        if (allDays24hours) {
          setSelectedDays((prevState) => [...prevState, "open24/7"]);
        }
        setService(updatedService);
        setOpeningHours(updatedOpeningHours);
      }
      setStep((prevState) => prevState + 1);
    } catch (error) {
      console.error("An error occurred:", error);
      alert(
        "This phone number is not in our database. Please make sure it matches the number that received the SMS.",
      );
    } finally {
      setComponentLoading(false);
    }
  };

  const putInformationOnObject = async () => {
    try {
      const updatedCompany = { ...company };
      // await put("/company/aggregate", company, token);
      // Actualizar basePrices en company a partir de service
      updatedCompany.basePrices = updatedCompany.basePrices || {};
      service.categories.forEach((category) => {
        if (selectedServices.includes(category.key)) {
          if (!updatedCompany.basePrices[category.key]) {
            updatedCompany.basePrices[category.key] = {};
          }
          Object.keys(category.subCategories).forEach((subKey) => {
            if (subKey === "mileageRate") {
              updatedCompany.basePrices.towing.flatbed.mileageRate =
                parseFloat(category.subCategories.mileageRate.value.basePrice) *
                100;
              updatedCompany.basePrices.towing.standard.mileageRate =
                parseFloat(category.subCategories.mileageRate.value.basePrice) *
                100;
              return;
            }
            const categoryIndex =
              updatedCompany.specialties.specialties.findIndex(
                (service) => service.name === category.key,
              );
            if (categoryIndex > -1) {
              const variantIndex = updatedCompany.specialties.specialties[
                categoryIndex
              ].variants.findIndex((variant) => variant === subKey);
              if (variantIndex === -1) {
                updatedCompany.specialties.specialties[
                  categoryIndex
                ].variants.push(
                  subKey === "tireReplacement" ? "replacement" : subKey,
                );
              }
            } else {
              const newSpecialty = {
                variants: [
                  subKey === "tireReplacement" ? "replacement" : subKey,
                ],
                name: category.key,
                service: "roadside",
              };
              updatedCompany.specialties.specialties.push(newSpecialty);
            }

            if (category.subCategories[subKey].value) {
              const subCategoryValue = category.subCategories[subKey].value;
              if (subCategoryValue.new) {
                updatedCompany.basePrices[category.key][subKey] = {
                  new: {
                    basePrice: parseFloat(subCategoryValue.new.basePrice) * 100,
                  },
                };
              } else if (subCategoryValue.basePrice) {
                updatedCompany.basePrices[category.key][subKey] = {
                  basePrice: parseFloat(subCategoryValue.basePrice) * 100,
                };
              }
            }
          });
        } else {
          const subCategories = category.subCategories;
          updatedCompany.specialties.specialties =
            updatedCompany.specialties.specialties.filter(
              (specialty) => specialty.name !== category.key,
            );
          for (let subCategory in subCategories) {
            if (category.subCategories[subCategory].value) {
              if (category.subCategories[subCategory].value.new) {
                category.subCategories[subCategory].value.new.basePrice =
                  parseFloat(
                    category.subCategories[subCategory].value.new.basePrice,
                  ) * 100;
              } else {
                category.subCategories[subCategory].value.basePrice =
                  parseFloat(
                    category.subCategories[subCategory].value.basePrice,
                  ) * 100;
              }
            }
          }
        }
      });
      updatedCompany.basePrices.tireChange.tireReplacement.used = {
        basePrice: 1500,
      };

      updatedCompany.basePrices.battery.replacement.used = {
        basePrice: 1500,
      };

      // Actualizar currentOpeningHours en company a partir de openingHours
      updatedCompany.detail = updatedCompany.detail || {};
      updatedCompany.detail.currentOpeningHours =
        updatedCompany.detail.currentOpeningHours || {};
      openingHours.categories.forEach((day) => {
        if (day.key === "open24/7") {
          return;
        }
        if (
          updatedCompany.detail.currentOpeningHours[day.key] &&
          !selectedDays.includes(day.key)
        ) {
          updatedCompany.detail.currentOpeningHours[day.key][0].open = "closed";
          updatedCompany.detail.currentOpeningHours[day.key][0].close =
            "closed";
          return;
        }
        if (!updatedCompany.detail.currentOpeningHours[day.key]) {
          updatedCompany.detail.currentOpeningHours[day.key] = [{}];
        }
        const hours = updatedCompany.detail.currentOpeningHours[day.key][0];
        hours.open =
          day.subCategories.startTime.value === "24 hours"
            ? "Open 24 hours"
            : day.subCategories.startTime.value;
        hours.close =
          day.subCategories.startTime.value === "24 hours"
            ? "closed"
            : day.subCategories.endTime.value;
      });
      updatedCompany.name = companyName;
      updatedCompany.basePrices.hasConfirmedPrices = true;
      updatedCompany.contact.reachable = true;
      updatedCompany.contact.smsEnabled = smsEnabled;
      updatedCompany.contact.email = email.length > 0 ? email : null;
      console.log("Email----->", email);
      setComponentLoading(true);
      put("/company/aggregate", updatedCompany, token);
      // Aquí puedes guardar el objeto company actualizado en el estado si es necesario
      // setCompany(updatedCompany);
    } catch (error) {
      console.error("An error occurred:", error);
      // Puedes agregar más lógica aquí para manejar el error, como mostrar un mensaje al usuario
    } finally {
      setComponentLoading(false);
      setStep((prevState) => prevState + 1);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#000",
        color: "#fff",
        width: "100%",
        paddingTop: "8vh",
        paddingBottom: "8vh",
      }}
    >
      <Grid container spacing={0} alignItems="stretch">
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            justifyContent: "center",
            marginRight: "-2vw",
          }}
        >
          <Box
            component="img"
            src={phoneInfo}
            alt="Phone Information"
            sx={{
              width: {
                md: "100%",
                xs: "80%",
              },
              height: "auto",
              objectFit: "contain", // Ajuste del objeto para contener la imagen
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          sx={{
            display: "flex",
            alignItems: "center",
            // border: "solid red",
            paddingRight: "1vw",
          }}
        >
          <Paper
            sx={{
              borderRadius: { sx: "0px", md: "40px" },
              background:
                "linear-gradient(180deg, rgba(54, 54, 54, 0.40) 0%, rgba(26, 26, 26, 0.83) 22.5%, rgba(20, 20, 20, 0.92) 39.5%, rgba(20, 20, 20, 0.92) 56.01%, #0F0F0F 75%)",
              boxShadow: "4px 8px 60px 0px rgba(0, 0, 0, 0.80)",
              width: "100%",
              // height: "86%",
              minHeight: { xs: "70vh", md: "86%" },
              display: "flex",
            }}
          >
            {/* caja contenedora */}

            <Box
              sx={{
                padding: { xs: "5vw", md: "2vw" },
                display: "flex",
                flexDirection: "column",
                alignItem: "center",
                justifyContent: "space-between",
                width: "100%",
                // border: "solid red",
              }}
            >
              {/* caja superior */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5vh",
                }}
              >
                {/*Titulo y descripcion */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: { xs: "center", md: "flex-start" },
                    gap: "2vh",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      fontSize: { xs: "1.7rem", md: "1.7rem" },
                      fontFamily: "Red Hat Display, sans-serif",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {formStep[step].title[0]}
                    <Box
                      sx={{
                        display: "inline-flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginLeft: "4px",
                        marginRight: "4px",
                      }}
                    >
                      <Box> {formStep[step].title[1]}</Box>
                      <Box
                        sx={{
                          height: "0.5vh", // Ajusta el grosor de la línea
                          backgroundColor: "#FF7C06",
                          width: "55%", // Ajusta la anchura de la línea
                          marginTop: "-0.6vh", // Ajusta el margen superior para acercarla más a "phone"
                          borderRadius: "16px",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "50vw", // Ajusta esta anchura según tus necesidades
                        marginLeft: "4px", // Opcional: ajusta el margen según sea necesario
                      }}
                    >
                      {formStep[step].title[2]}
                    </Box>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#B8B8B8",
                      fontFamily: "Red Hat Display, sans-serif",
                      textAlign: "left",
                    }}
                  >
                    {formStep[step].description}
                  </Typography>
                </Box>

                {/*  contenido */}
                {formStep[step].component}
              </Box>
              {/* caja inferior */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: formStep[step].buttons[1]
                    ? "space-between"
                    : "flex-end",
                  width: "100%",
                }}
              >
                {formStep[step].buttons[1] && (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "transparent",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "#e0e0e0",
                      },
                      fontSize: "1rem",
                      fontFamily: "Inter, sans-serif",
                      paddingLeft: "4.5vw",
                      paddingRight: "4.5vw",
                      borderRadius: "12px",
                      fontWeight: "600",
                      textTransform: "none", // Añadir esta línea
                      border: "solid 0.1vw #FFF",
                      width: { xs: "35vw", md: "12vw" },
                    }}
                    onClick={() => {
                      setSelectedButton(false);
                      setStep((prevState) => prevState - 1);
                    }}
                  >
                    {formStep[step].buttons[1]}
                  </Button>
                )}
                {formStep[step].buttons[0] && (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#fff",
                      color: "#000",
                      "&:hover": {
                        backgroundColor: "#e0e0e0",
                      },
                      fontSize: "1rem",
                      fontFamily: "Inter, sans-serif",
                      paddingLeft: "4.5vw",
                      paddingRight: "4.5vw",
                      borderRadius: "12px",
                      fontWeight: "600",
                      textTransform: "none", // Añadir esta línea
                      width: { xs: "35vw", md: "12vw" },
                      "&.Mui-disabled": componentLoading
                        ? {
                            backgroundColor: "#fff",
                            color: "#000",
                            opacity: 1, // Opcional: para mantener la opacidad original
                          }
                        : {
                            backgroundColor: "#6F6F6F99",
                          },
                    }}
                    onClick={handleNextButton}
                    disabled={
                      (step === 1 && !selectedButton) ||
                      componentLoading ||
                      (step === 4 && selectedDays.length === 0) ||
                      (step === 2 &&
                        (!isValidEmail(email) || companyName.length === 0))
                    }
                  >
                    {componentLoading ? (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "#000",
                          position: "relative",
                        }}
                      />
                    ) : (
                      formStep[step].buttons[0]
                    )}
                  </Button>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;
