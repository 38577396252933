/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import pin from "../assets/map-pin.svg"; // Ajusta la ruta según corresponda

const About = () => {
  return (
    <Box
      sx={{
        paddingTop: "16vh",
        paddingBottom: "16vh",
        paddingLeft: "3vw",
        paddingRight: "3vw",
        display: "flex",
        flexDirection: "column",
        gap: "10vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Cambia de columna en pantallas pequeñas a fila en pantallas más grandes
          gap: "3vw",
          alignItems: { xs: "center", md: "center" },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "10vw", sm: "8vw", md: "2.3rem", lg: "2.3rem" },
            fontWeight: "600",
            fontFamily: "Red Hat Display, sans-serif",
            whiteSpace: "nowrap",
          }}
        >
          Why{" "}
          <Box
            sx={{
              display: "inline-flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "4px",
              marginRight: "4px",
            }}
          >
            <Box>us</Box>
            <Box
              sx={{
                height: "0.5vh", // Ajusta el grosor de la línea
                backgroundColor: "#FF7C06",
                width: "55%", // Ajusta la anchura de la línea
                marginTop: "-0.9vh", // Ajusta el margen superior para acercarla más a "phone"
                borderRadius: "16px",
              }}
            />
          </Box>
          ?
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "4.5vw", sm: "3vw", md: "1rem", lg: "1.3rem" },
            fontFamily: "Red Hat Display, sans-serif",
            textAlign: { xs: "center", md: "left" },
            position: "relative", // Añadido para posicionamiento relativo
          }}
        >
          Roadr connects drivers with nearby roadside companies for fast
          assistance with towing, tire changes, refueling, and more, all via our
          user-friendly app. Join Roadr to gain visibility within 3 minutes for
          free! Clients can call your company directly, ensuring accurate
          pricing and no surprises. Complete your information today to keep 100%
          of your earnings and become part of a trusted network.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Cambia de columna en pantallas pequeñas a fila en pantallas más grandes
          gap: "2.5vw",
        }}
      >
        <Paper
          sx={{
            display: "flex",
            alignItems: "flex-start",
            padding: "16px",
            borderRadius: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "0.7vw",
              }}
            >
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#1279F2",
                  borderRadius: "50%",
                }}
              />
              <Typography
                sx={{
                  fontWeight: "600",
                  textAlign: "left",
                  fontFamily: "Red Hat Display, sans-serif",
                  fontSize: "1.2em",
                }}
              >
                Greater Visibility
              </Typography>
            </Box>
            <Typography
              sx={{
                textAlign: "left",
                fontFamily: "Red Hat Display, sans-serif",
              }}
            >
              By updating your data, your workshop will be more easily found by
              customers looking for specific services.
            </Typography>
          </Box>
        </Paper>
        <Paper
          sx={{
            display: "flex",
            alignItems: "flex-start",
            padding: "16px",
            borderRadius: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "0.7vw",
              }}
            >
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#1279F2",
                  borderRadius: "50%",
                }}
              />
              <Typography
                sx={{
                  fontWeight: "600",
                  textAlign: "left",
                  fontFamily: "Red Hat Display, sans-serif",
                  fontSize: "1.2em",
                }}
              >
                Accurate Services
              </Typography>
            </Box>
            <Typography
              sx={{
                textAlign: "left",
                fontFamily: "Red Hat Display, sans-serif",
              }}
            >
              Up-to-date information ensures that customers know exactly what
              services you offer and your prices.
            </Typography>
          </Box>
        </Paper>
        <Paper
          sx={{
            display: "flex",
            alignItems: "flex-start",
            padding: "16px",
            borderRadius: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "0.7vw",
              }}
            >
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#1279F2",
                  borderRadius: "50%",
                }}
              />
              <Typography
                sx={{
                  fontWeight: "600",
                  textAlign: "left",
                  fontFamily: "Red Hat Display, sans-serif",
                  fontSize: "1.2em",
                }}
              >
                Better Planning
              </Typography>
            </Box>
            <Typography
              sx={{
                textAlign: "left",
                fontFamily: "Red Hat Display, sans-serif",
              }}
            >
              Customers who are informed about your opening hours can plan
              visits and appointments more easily
            </Typography>
          </Box>
        </Paper>
      </Box>

      <Box>
        <Paper
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "24px",
            borderRadius: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "24px",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              component="img"
              src={pin}
              alt="Descripción de la imagen"
              sx={{
                width: { xs: "30%", sm: "30%", md: "5vw", lg: "3vw" }, // Tamaño responsivo
                height: { xs: "auto", sm: "auto", md: "auto" }, // Ajusta la altura automáticamente
                "@media (orientation: landscape) and (max-width: 767px)": {
                  display: "none", // Oculta el componente en dispositivos móviles en orientación landscape
                },
              }}
            />
            <Typography
              sx={{
                textAlign: { xs: "center", md: "left" },
                fontFamily: "Red Hat Display, sans-serif",
                fontSize: "1.1em",
                fontWeight: "400",
              }}
            >
              To ensure that your services are found by more customers and to
              offer the best possible experience, we need you to update your
              information. Let's work together to highlight everything your
              workshop has to offer!
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default About;
