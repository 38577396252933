export const REGIONS = [
    { name: "United States", code: "+1", abbreviation: "US", flag: "🇺🇸" },
    { name: "Afghanistan", code: "+93", abbreviation: "AF", flag: "🇦🇫" },
    { name: "Argentina", code: "+54", abbreviation: "AR", flag: "🇦🇷" },
    { name: "Australia", code: "+61", abbreviation: "AU", flag: "🇦🇺" },
    { name: "Austria", code: "+43", abbreviation: "AT", flag: "🇦🇹" },
    { name: "Bangladesh", code: "+880", abbreviation: "BD", flag: "🇧🇩" },
    { name: "Belgium", code: "+32", abbreviation: "BE", flag: "🇧🇪" },
    { name: "Brazil", code: "+55", abbreviation: "BR", flag: "🇧🇷" },
    { name: "Bulgaria", code: "+359", abbreviation: "BG", flag: "🇧🇬" },
    { name: "Canada", code: "+1", abbreviation: "CA", flag: "🇨🇦" },
    { name: "Chile", code: "+56", abbreviation: "CL", flag: "🇨🇱" },
    { name: "China", code: "+86", abbreviation: "CN", flag: "🇨🇳" },
    { name: "Colombia", code: "+57", abbreviation: "CO", flag: "🇨🇴" },
    { name: "Czech Republic", code: "+420", abbreviation: "CZ", flag: "🇨🇿" },
    { name: "Denmark", code: "+45", abbreviation: "DK", flag: "🇩🇰" },
    { name: "Egypt", code: "+20", abbreviation: "EG", flag: "🇪🇬" },
    { name: "Finland", code: "+358", abbreviation: "FI", flag: "🇫🇮" },
    { name: "France", code: "+33", abbreviation: "FR", flag: "🇫🇷" },
    { name: "Germany", code: "+49", abbreviation: "DE", flag: "🇩🇪" },
    { name: "Ghana", code: "+233", abbreviation: "GH", flag: "🇬🇭" },
    { name: "Greece", code: "+30", abbreviation: "GR", flag: "🇬🇷" },
    { name: "Hungary", code: "+36", abbreviation: "HU", flag: "🇭🇺" },
    { name: "India", code: "+91", abbreviation: "IN", flag: "🇮🇳" },
    { name: "Indonesia", code: "+62", abbreviation: "ID", flag: "🇮🇩" },
    { name: "Iran", code: "+98", abbreviation: "IR", flag: "🇮🇷" },
    { name: "Iraq", code: "+964", abbreviation: "IQ", flag: "🇮🇶" },
    { name: "Ireland", code: "+353", abbreviation: "IE", flag: "🇮🇪" },
    { name: "Israel", code: "+972", abbreviation: "IL", flag: "🇮🇱" },
    { name: "Italy", code: "+39", abbreviation: "IT", flag: "🇮🇹" },
    { name: "Japan", code: "+81", abbreviation: "JP", flag: "🇯🇵" },
    { name: "Kenya", code: "+254", abbreviation: "KE", flag: "🇰🇪" },
    { name: "Malaysia", code: "+60", abbreviation: "MY", flag: "🇲🇾" },
    { name: "Mexico", code: "+52", abbreviation: "MX", flag: "🇲🇽" },
    { name: "Netherlands", code: "+31", abbreviation: "NL", flag: "🇳🇱" },
    { name: "New Zealand", code: "+64", abbreviation: "NZ", flag: "🇳🇿" },
    { name: "Nigeria", code: "+234", abbreviation: "NG", flag: "🇳🇬" },
    { name: "Norway", code: "+47", abbreviation: "NO", flag: "🇳🇴" },
    { name: "Pakistan", code: "+92", abbreviation: "PK", flag: "🇵🇰" },
    { name: "Peru", code: "+51", abbreviation: "PE", flag: "🇵🇪" },
    { name: "Philippines", code: "+63", abbreviation: "PH", flag: "🇵🇭" },
    { name: "Poland", code: "+48", abbreviation: "PL", flag: "🇵🇱" },
    { name: "Portugal", code: "+351", abbreviation: "PT", flag: "🇵🇹" },
    { name: "Romania", code: "+40", abbreviation: "RO", flag: "🇷🇴" },
    { name: "Russia", code: "+7", abbreviation: "RU", flag: "🇷🇺" },
    { name: "Saudi Arabia", code: "+966", abbreviation: "SA", flag: "🇸🇦" },
    { name: "South Africa", code: "+27", abbreviation: "ZA", flag: "🇿🇦" },
    { name: "South Korea", code: "+82", abbreviation: "KR", flag: "🇰🇷" },
    { name: "Spain", code: "+34", abbreviation: "ES", flag: "🇪🇸" },
    { name: "Sweden", code: "+46", abbreviation: "SE", flag: "🇸🇪" },
    { name: "Switzerland", code: "+41", abbreviation: "CH", flag: "🇨🇭" },
    { name: "Thailand", code: "+66", abbreviation: "TH", flag: "🇹🇭" },
    { name: "Turkey", code: "+90", abbreviation: "TR", flag: "🇹🇷" },
    { name: "Uganda", code: "+256", abbreviation: "UG", flag: "🇺🇬" },
    { name: "Ukraine", code: "+380", abbreviation: "UA", flag: "🇺🇦" },
    { name: "United Arab Emirates", code: "+971", abbreviation: "AE", flag: "🇦🇪" },
    { name: "United Kingdom", code: "+44", abbreviation: "GB", flag: "🇬🇧" },
    { name: "Venezuela", code: "+58", abbreviation: "VE", flag: "🇻🇪" },
    { name: "Vietnam", code: "+84", abbreviation: "VN", flag: "🇻🇳" }
];
