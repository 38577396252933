/**
 * Validates if a given string is a valid email address.
 *
 * This function uses a regular expression to check if the input string
 * matches the standard pattern for email addresses. The pattern used
 * considers common email formats but may not cover all edge cases.
 *
 * @param {string} email - The string to be validated as an email.
 * @returns {boolean} - Returns true if the string is a valid email address, otherwise false.
 *
 * @example
 * // Returns true
 * isValidEmail('example@test.com');
 *
 * @example
 * // Returns false
 * isValidEmail('example@test');
 *
 * @example
 * // Returns false
 * isValidEmail('example.com');
 */
export function isValidEmail(email) {
    // Regular expression pattern for basic email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}
