import React from "react";
import { Box, Typography, Link, useMediaQuery } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import RoadrLogo from "../assets/RoadrLogo.svg"; // Ajusta la ruta según corresponda
import GoogleLogo from "../assets/Google-Badge.svg"; // Ajusta la ruta según corresponda
import AppleLogo from "../assets/Apple-Badge.svg"; // Ajusta la ruta según corresponda

const Footer = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={{
        backgroundColor: "#000",
        color: "var(--Dark-White, var(--Surface-Surface900, #F8F8F8))",
        paddingTop: "4vh",
        paddingLeft: "3vw",
        paddingRight: "3vw",
        paddingBottom: "2vh",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: isMobile ? "center" : "space-between",
        alignItems: isMobile ? "flex-start" : "center",
        borderTop: "1px solid #444",
        fontFamily: "Red Hat Text",
        gap: isMobile ? "2vh" : "0", // Añade espacio entre los elementos en mobile
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5vh",
        }}
      >
        <Box>
          <Typography
            sx={{
              textAlign: "left",
              fontWeight: "400",
              color: "#F8F8F8",
              fontFamily: "Red Hat Text, sans-serif",
            }}
            variant="h6"
          >
            Contact us:
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <EmailIcon />
          <Link
            href="mailto:info@roadr.com"
            color="inherit"
            sx={{
              ml: 1,
              fontWeight: "300",
              color: "#F8F8F8",
              fontFamily: "Red Hat Text, sans-serif",
            }}
          >
            info@roadr.com
          </Link>
        </Box>
        <Box display="flex" alignItems="center">
          <PhoneIcon />
          <Typography
            variant="body1"
            sx={{
              ml: 1,
              fontWeight: "300",
              color: "#F8F8F8",
              fontFamily: "Red Hat Text, sans-serif",
            }}
          >
            +1 (877) 527-6237
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: isMobile ? "center" : "flex-end",
          gap: "1rem",
          flex: isMobile ? "0 1 auto" : 1,
          width: "100%",
        }}
      >
        <Link href="https://apps.apple.com/us/app/roadr/id6443495201" target="_blank" rel="noopener noreferrer">
          <Box
            component="img"
            src={AppleLogo}
            alt="Apple App Store"
            sx={{ width: 140 }}
          />
        </Link>
        <Link href="https://play.google.com/store/apps/details?id=com.roadr" target="_blank" rel="noopener noreferrer">
          <Box
            component="img"
            src={GoogleLogo}
            alt="Google Play Store"
            sx={{ width: 140 }}
          />
        </Link>
      </Box>
      <Box
        sx={{
          flex: isMobile ? "0 1 auto" : 1,
          display: "flex",
          justifyContent: isMobile ? "center" : "flex-end",
          // border: "solid red",
          width: "100%",
        }}
      >
        <Link href="https://roadr.com/" target="_blank" rel="noopener noreferrer">
          <Box
            component="img"
            src={RoadrLogo}
            alt="Roadr Logo"
            sx={{ width: 150, cursor: 'pointer' }}
          />
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
