/* eslint-disable react/prop-types */
import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const Step3 = ({ inputValue, onInputChange, icon: Icon, placeholder }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <TextField
        variant="outlined"
        placeholder={placeholder ?? ""}
        value={inputValue}
        onChange={(e) => onInputChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box
                sx={{
                  backgroundColor: "#252424",
                  borderRadius: "50%",
                  padding: "0.3vw",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  transform: "scale(0.8)",
                }}
              >
                <Icon
                  sx={{
                    color: "#fff",
                  }}
                />
              </Box>
            </InputAdornment>
          ),
          style: { color: "#fff", height: "4.5vh" },
        }}
        InputLabelProps={{
          style: { color: "#fff" },
        }}
        sx={{
          backgroundColor: "#191919",
          borderRadius: "8px",
          height: "4.5vh",
          width: { xs: "100%", md: "28.25rem", lg: "29.5rem" },
          "& .MuiInputBase-input::placeholder": {
            color: "#fff",
          },
        }}
      />
    </Box>
  );
};

export default Step3;
