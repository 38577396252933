/* eslint-disable react/prop-types */
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Autocomplete, TextField, Box, Popper, Paper } from "@mui/material";
import { REGIONS } from "../../helpers/constants";

const CustomCheckbox = styled(Checkbox)({
  color: "#252424",
  "&.Mui-checked": {
    color: "#2BC153",
  },
  "& svg": {
    borderRadius: "50%",
    backgroundColor: "#252424",
  },
  "&.Mui-checked svg": {
    borderRadius: "50%",
    backgroundImage: "radial-gradient(circle, white 50%, #252424 31%)",
  },
});

// Componente personalizado Popper
const StyledPopper = styled(Popper)(() => ({
  ".MuiAutocomplete-paper": {
    backgroundColor: "#252424",
    color: "#fff",
  },
}));

// Componente personalizado Paper
const StyledPaper = styled(Paper)(() => ({
  backgroundColor: "#252424",
  color: "#fff",
}));

const Step1 = (props) => {
  const handleRegionChange = (event, value) => {
    props.resetPhoneNumber();
    const selectedIndex = REGIONS.findIndex((region) => region.name === value);
    props.setRegionIndex(selectedIndex);
  };

  const selectedRegion = REGIONS[props.regionIndex];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        fontFamily: "Red Hat Display, sans-serif",
        color: "#fff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1rem",
          gap: "0.5vw",
        }}
      >
        <Autocomplete
          options={REGIONS.map((region) => region.name)}
          onChange={handleRegionChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select country"
              sx={{
                marginRight: "1rem",
                backgroundColor: "#252424",
                color: "#fff",
                borderRadius: "8px",
                fontFamily: "Red Hat Display, sans-serif",
                "& .MuiInputBase-input": {
                  color: "#fff",
                  padding: 0,
                  marginTop: "-0.5rem",
                },
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#fff",
                    height: "2.5rem",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
                height: "2.5rem",
              }}
            />
          )}
          PopperComponent={StyledPopper}
          PaperComponent={StyledPaper}
          sx={{
            width: "5rem",
            "& .MuiAutocomplete-inputRoot": {
              paddingRight: "8px !important",
            },
            "& .MuiAutocomplete-endAdornment": {
              display: "none",
            },
            height: "2.5rem",
          }}
          value={selectedRegion ? selectedRegion.name : ""}
          getOptionLabel={(option) => {
            const region = REGIONS.find((r) => r.name === option);
            return region ? `${region.flag} ${region.abbreviation}` : "";
          }}
          renderOption={(props, option, { selected }) => {
            const region = REGIONS.find((r) => r.name === option);
            return (
              <Box
                component="li"
                {...props}
                sx={{
                  height: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  background: selected ? "#3a3a3a !important" : "#252424",
                  "&.Mui-focused": {
                    background: "#3a3a3a !important",
                  },
                  "&:hover": {
                    backgroundColor: "#3a3a3a !important",
                  },
                }}
              >
                <span style={{ marginRight: "8px" }}>{region.flag}</span>
                {region.abbreviation}
              </Box>
            );
          }}
        />

        <TextField
          variant="outlined"
          placeholder="Phone number"
          value={props.phoneNumber}
          onChange={props.handlePhoneNumberChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  sx={{
                    backgroundColor: "#252424",
                    borderRadius: "50%",
                    padding: "0.3vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transform: "scale(0.8)",
                  }}
                >
                  <PhoneOutlinedIcon
                    sx={{
                      color: "#fff",
                    }}
                  />
                </Box>
              </InputAdornment>
            ),
            style: {
              color: "#fff",
              height: "2.5rem",
              fontFamily: "Red Hat Display, sans-serif",
            },
          }}
          InputLabelProps={{
            style: { color: "#fff" },
          }}
          sx={{
            backgroundColor: "#252424",
            borderRadius: "8px",

            height: "2.5rem",
            width: { xs: "100%", md: "23rem", lg: "24rem" },
            "& .MuiInputBase-input::placeholder": {
              color: "#fff",
            },
          }}
        />
      </Box>
      <FormControlLabel
        control={
          <CustomCheckbox
            checked={props.smsConsent}
            onChange={() => {
              props.handleSmsConsentChange((prevState) => !prevState);
            }}
            icon={<CheckCircleIcon />}
            checkedIcon={<CheckCircleIcon />}
          />
        }
        label="The phone number above can receive SMS"
        sx={{
          color: "#fff",
          fontFamily: "Red Hat Display, sans-serif",
          marginTop: "8px",
        }}
      />
    </Box>
  );
};

export default Step1;
