/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Button, Box } from "@mui/material";

const Step2 = ({ buttons, setChangeCompanyName, setSelected }) => {
  const [selectedButton, setSelectedButton] = useState(null);

  const handleClick = (index, onClick) => {
    setSelectedButton(index);
    setSelected(true);
    onClick();
    if (index === 0) {
      setChangeCompanyName(true);
    } else {
      setChangeCompanyName(false);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="row"
      gap="1vw"
    >
      {buttons.map((button, index) => (
        <Button
          key={index}
          onClick={() => handleClick(index, button.onClick)}
          sx={{
            bgcolor: selectedButton === index ? "white" : "transparent",
            color: selectedButton === index ? "black" : "white",
            "&:hover": {
              bgcolor: selectedButton === index ? "white" : "black",
              color: selectedButton === index ? "black" : "white",
            },
            border: "solid #F4F4F4 0.5px",
            borderRadius: "16px",
            textTransform: "none",
            fontFamily: "Inter, sans-serif",
          }}
        >
          {button.text}
        </Button>
      ))}
    </Box>
  );
};

export default Step2;
