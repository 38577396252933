/* eslint-disable react/prop-types */
import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  Collapse,
  TextField,
  InputAdornment,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CustomSelect = styled(Select)({
  width: "100%",
  height: "45%",
  borderRadius: "12px",
  backgroundColor: "#191919",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#252424",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
  "& .MuiSvgIcon-root": {
    color: "white",
  },
});

// Estilo personalizado para el Checkbox
const CustomCheckbox = styled(Checkbox)({
  color: "#252424",
  "&.Mui-checked": {
    color: "#2BC153",
    "& svg": {},
  },
  "& svg": {
    borderRadius: "50%",
    backgroundColor: "#252424",
  },
  "&.Mui-checked svg": {
    // borderRadius: "50%",
    backgroundImage: "radial-gradient(circle, white 50%, #252424 31%)",
  },
});

// Estilo personalizado para el Input Adornment
const CustomAdornment = styled(Box)({
  color: "white",
  backgroundColor: "#252424",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "4px",
});

// Estilo personalizado para el TextField
const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: "white",
    backgroundColor: "#191919",
    borderRadius: "16px",
    fontSize: "0.8rem", // Reducir el tamaño de la fuente
    padding: "6px", // Reducir el padding para hacerlo más pequeño
    height: "80%", // Ajustar la altura al 80%
  },
  "& .MuiInputLabel-root": {
    color: "white",
    fontSize: "0.8rem", // Reducir el tamaño de la fuente de la etiqueta
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    // borderColor: "#252424",
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
  "& .MuiInputBase-input": {
    fontSize: "0.8rem", // Reducir el tamaño de la fuente del input
    height: "80%", // Ajustar la altura del input al 80%
    "&::placeholder": {
      color: "white",
      fontFamily: "Inter, sans-serif",
      fontSize: "0.8rem", // Reducir el tamaño de la fuente del placeholder
    },
  },
});
const RadioButtonCategories = ({
  data,
  twoInRow = false,
  dateSelection = false,
  setCategories,
  selectedServices,
  setSelectedServices,
}) => {
  const { categories, icon: IconComponent, placeHolder } = data;

  const handleServiceChange = (event, serviceKey) => {
    if (serviceKey === "open24/7") {
      let checked = event.target.checked;
      setCategories((prevValues) => {
        let auxValues = { ...prevValues };
        for (let index in auxValues.categories) {
          let dayData = auxValues.categories[index];
          if (dayData.subCategories.startTime)
            dayData.subCategories.startTime.value = "24 hours";
        }
        return auxValues;
      });
      setSelectedServices(() => {
        if (!checked) {
          return [];
        } else {
          return [
            "open24/7",
            "sunday",
            "tuesday",
            "monday",
            "wednesday",
            "thursday",
            "saturday",
            "friday",
          ];
        }
      });
      return;
    } else {
      setSelectedServices((prev) =>
        event.target.checked
          ? [...prev, serviceKey].filter((key) => key !== "open24/7")
          : prev.filter((key) => key !== serviceKey && key !== "open24/7"),
      );
    }
  };

  const handleSubValueChange = (category, subKey, value) => {
    // Validar que si es un número flotante, solo tenga un punto y dos decimales
    const isValidFloat = (val) => {
      const regex = /^\d*\.?\d{0,2}$/;
      return regex.test(val);
    };
    const aux_category = { ...category };
    if (category.subCategories[subKey].value && dateSelection) {
      aux_category.subCategories[subKey].value = value;
    } else {
      if (isValidFloat(value)) {
        console.log("The value------>", value);
        console.log("The subkey------>", subKey);
        console.log(
          "The basePrice------>",
          aux_category.subCategories[subKey].value.basePrice,
        );

        if (category.subCategories[subKey].value.new) {
          aux_category.subCategories[subKey].value.new.basePrice = value;
        } else {
          aux_category.subCategories[subKey].value.basePrice = value;
        }
      } else {
        console.error(
          "Invalid value: Only numbers with up to 2 decimal places are allowed.",
        );
      }
    }

    setSelectedServices((prevValues) =>
      prevValues.filter((key) => key !== "open24/7"),
    );

    setCategories((prevValues) => ({
      ...prevValues,
      [aux_category.key]: aux_category,
    }));
  };

  const hours = Array.from({ length: 24 }, (_, i) => {
    const hour = i % 12 === 0 ? 12 : i % 12;
    const period = i < 12 ? "AM" : "PM";
    return `${hour}:00 ${period}`;
  });
  return (
    <Box
      sx={{
        mb: "4vh",
      }}
    >
      <FormControl
        component="fieldset"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: twoInRow ? "row" : "column" },
          flexWrap: twoInRow ? "wrap" : "nowrap",
        }}
      >
        {categories.map((service) => (
          <Box
            key={service.key}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", md: twoInRow ? "50%" : "100%" },
              justifyContent: "center",
              mt:
                service.key === "open24/7" &&
                selectedServices.includes("open24/7")
                  ? "4vh"
                  : "",
            }}
          >
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={selectedServices.includes(service.key)}
                  onChange={(e) => handleServiceChange(e, service.key)}
                  icon={<CheckCircleIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              }
              label={service.title}
              sx={{
                color: "#FFF",
              }}
              componentsProps={{
                typography: {
                  sx: {
                    fontFamily: "Red Hat Display, sans-serif",
                    fontWeight: "500",
                  },
                },
              }}
            />
            <Collapse in={selectedServices.includes(service.key)}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: { xs: "wrap", md: "nowrap" },
                }}
              >
                {Object.entries(service.subCategories).map(([subKey]) => {
                  if (
                    dateSelection &&
                    service.subCategories[subKey].title.includes("End time") &&
                    service.subCategories["startTime"].value === "24 hours"
                  ) {
                    return <></>;
                  }
                  return (
                    <Box
                      key={subKey}
                      sx={{
                        mr: 2,
                        mb: 2,
                        // border: "solid red",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: twoInRow ? "100%" : "auto",
                        // border: "solid red",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "white",
                          mb: 1,
                          fontFamily: "Inter, sans-serif",
                        }}
                      >
                        {dateSelection &&
                        service.subCategories["startTime"].value === "24 hours"
                          ? "Working hours"
                          : service.subCategories[subKey].title}
                      </Typography>
                      {dateSelection ? (
                        <CustomSelect
                          key={subKey}
                          value={service.subCategories[subKey].value || ""}
                          startAdornment={
                            <InputAdornment position="start">
                              {IconComponent && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "26px", // Ajusta según sea necesario
                                    height: "26px", // Ajusta según sea necesario
                                    backgroundColor: "#252424",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <IconComponent
                                    sx={{
                                      transform: "scale(0.7)",
                                      color: "white",
                                    }}
                                  />
                                </Box>
                              )}
                            </InputAdornment>
                          }
                          onChange={(e) =>
                            handleSubValueChange(
                              service,
                              subKey,
                              e.target.value,
                            )
                          }
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <Typography
                                  sx={{
                                    color: "#B8B8B8",
                                    // border: "solid red",
                                    textAlign: "left",
                                  }}
                                >
                                  Hour
                                </Typography>
                              );
                            } else {
                              return selected;
                            }
                          }}
                          displayEmpty
                          sx={{
                            color: "white",
                            backgroundColor: "inherit",
                            "& .MuiSelect-icon": { color: "white" },
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: { xs: 200, md: 300 }, // Ajusta la altura máxima en modo móvil y de escritorio
                                backgroundColor: "#191919",
                              },
                            },
                          }}
                        >
                          {!service.subCategories[subKey].title.includes(
                            "End time",
                          ) && (
                            <MenuItem
                              sx={{
                                backgroundColor: "#191919",
                                color: "white",
                                borderBottom: "solid 1px #7E7E7E",
                              }}
                              value="24 hours"
                            >
                              24 hours
                            </MenuItem>
                          )}

                          {hours.map((hour) => (
                            <MenuItem
                              sx={{
                                backgroundColor: "#191919",
                                color: "white",
                                borderTop: "solid 0px #7E7E7E",
                              }}
                              key={hour}
                              value={hour}
                            >
                              {hour}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      ) : (
                        <CustomTextField
                          placeholder={placeHolder}
                          value={
                            service.subCategories[subKey].value
                              ? service.subCategories[subKey].value.new
                                ? service.subCategories[subKey].value.new
                                    .basePrice > 0
                                  ? service.subCategories[subKey].value.new
                                      .basePrice
                                  : 0
                                : service.subCategories[subKey].value
                                      .basePrice > 0
                                  ? service.subCategories[subKey].value
                                      .basePrice
                                  : ""
                              : ""
                          }
                          onChange={(e) =>
                            handleSubValueChange(
                              service,
                              subKey,
                              e.target.value,
                            )
                          }
                          InputProps={{
                            startAdornment: IconComponent && (
                              <InputAdornment position="start">
                                <CustomAdornment>
                                  <IconComponent />
                                </CustomAdornment>
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            style: { color: "white", fontSize: "0.8rem" },
                          }}
                        />
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Collapse>
          </Box>
        ))}
      </FormControl>
    </Box>
  );
};

export default RadioButtonCategories;
