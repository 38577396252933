import React from "react";
import { Box, Typography } from "@mui/material";
import phones from "../assets/image-landing-roadr2.webp"; // Ajusta la ruta según corresponda

const Header = () => {
  return (
    <Box
      component="header"
      sx={{
        backgroundColor: "black",
        color: "white",
        textAlign: "center",
        height: "100vh", // Ocupa el 100% de la altura de la pantalla
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Cambia la dirección según el tamaño de la pantalla
        justifyContent: "center", // Centra el contenido verticalmente
        alignItems: "center", // Centra el contenido horizontalmente
        paddingLeft: "38px",
        paddingRight: "38px",
        "@media (orientation: landscape)": {
          flexDirection: "row", // Cambia la dirección a fila en modo landscape
        },
      }}
    >
      <Box
        sx={{
          order: { xs: 2, md: 1 }, // Orden de visualización (texto primero en pantallas grandes, segundo en pequeñas)
          "@media (orientation: landscape)": {
            order: 1, // Asegura que el texto esté primero en modo landscape
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "4vw", sm: "4vw", md: "1.1rem", lg: "1.8rem" }, // Ajustado a vw
              fontWeight: "400",
              color: "grey",
              fontFamily: "Red Hat Display, sans-serif",
              letterSpacing: "0.1em", // Espaciado entre letras
            }}
          >
            ROADR SPECIALIST
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column", // Alinea verticalmente
            alignItems: "flex-start", // Alinea a la izquierda
            gap: "10px", // Espacio reducido
          }}
        >
          <Box>
            <Typography
              variant="h1"
              sx={{
                fontSize: {
                  xs: "5.5vw",
                  sm: "5vw",
                  md: "2.5rem",
                  lg: "3.2rem",
                }, // Ajustado a vw
                fontWeight: "bold",
                color: "white",
                fontFamily: "Red Hat Display, sans-serif",
                display: "inline", // Asegura que el texto se mantenga en línea,
                marginRight: "1vw",
                letterSpacing: "0.05em", // Espaciado entre letras
              }}
            >
              UPDATE
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "5.5vw",
                  sm: "5vw",
                  md: "2.5rem",
                  lg: "3.2rem",
                }, // Ajustado a vw
                fontWeight: "300",
                color: "white",
                fontFamily: "Red Hat Display, sans-serif",
                display: "inline", // Asegura que el texto se mantenga en línea
                marginRight: "1vw",
                letterSpacing: "0.05em", // Espaciado entre letras
              }}
            >
              YOUR
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "5.5vw",
                  sm: "5vw",
                  md: "2.5rem",
                  lg: "3.2rem",
                }, // Ajustado a vw
                fontWeight: "500",
                color: "orange",
                fontFamily: "Red Hat Display, sans-serif",
                display: "inline", // Asegura que el texto se mantenga en línea
                letterSpacing: "0.1em", // Espaciado entre letras
              }}
            >
              INFORMATION
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xs: "5.5vw",
                  sm: "5vw",
                  md: "2.5rem",
                  lg: "3.2rem",
                }, // Ajustado a vw
                fontWeight: "300",
                color: "white",
                fontFamily: "Red Hat Display, sans-serif",
                display: "inline",
                marginRight: "1vw",
                letterSpacing: "0.05em", // Espaciado entre letras
              }}
            >
              AND
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "5.5vw",
                  sm: "5vw",
                  md: "2.5rem",
                  lg: "3.2rem",
                }, // Ajustado a vw
                fontWeight: "500",
                color: "orange",
                fontFamily: "Red Hat Display, sans-serif",
                display: "inline",
                marginRight: "1vw", // Asegura que el texto se mantenga en línea
                letterSpacing: "0.1em", // Espaciado entre letras
              }}
            >
              CONNECT
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "5.5vw",
                  sm: "5vw",
                  md: "2.5rem",
                  lg: "3.2rem",
                }, // Ajustado a vw
                fontWeight: "300",
                color: "white",
                fontFamily: "Red Hat Display, sans-serif",
                display: "inline", // Asegura que el texto se mantenga en línea
                letterSpacing: "0.05em", // Espaciado entre letras
              }}
            >
              WITH MORE
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xs: "5.5vw",
                  sm: "5vw",
                  md: "2.5rem",
                  lg: "3.2rem",
                }, // Ajustado a vw
                fontWeight: "bold",
                color: "white",
                fontFamily: "Red Hat Display, sans-serif",
                display: "inline", // Asegura que el texto se mantenga en línea,
                marginRight: "1vw",
                letterSpacing: "0.1em", // Espaciado entre letras
              }}
            >
              CUSTOMERS.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        component="img"
        src={phones}
        alt="Descripción de la imagen"
        sx={{
          objectFit: "contain", // Ajuste del objeto para contener la imagen
          order: { xs: 1, md: 2 }, // Orden de visualización (imagen primera en pantallas pequeñas, segunda en grandes)
          width: { xs: "100%", sm: "70%", md: "100%", lg: "40%" }, // Tamaño responsivo
          height: { xs: "auto", sm: "auto", md: "auto" }, // Ajusta la altura automáticamente
          marginBottom: { xs: "24px", md: "0" }, // Añade margen inferior en dispositivos pequeños
          "@media (orientation: landscape) and (max-width: 767px)": {
            display: "none", // Oculta el componente en dispositivos móviles en orientación landscape
          },
        }}
      />
    </Box>
  );
};

export default Header;
