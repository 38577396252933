/* eslint-disable no-undef */
export const APP_ENVIRONMENT = process.env.REACT_APP_VERCEL_ENV ? process.env.REACT_APP_VERCEL_ENV : 'QA';

const getApiUrl = () => {
    if (APP_ENVIRONMENT === 'production') {
      return process.env.REACT_APP_API_URL_PROD;
    } else if (APP_ENVIRONMENT === 'development') {
      return process.env.REACT_APP_API_URL_DEV;
    } else {
      return process.env.REACT_APP_API_URL_QA;
    }
  };
  
export default getApiUrl;